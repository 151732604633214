import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.use(ElementUI);
Vue.config.productionTip = false
let y = {}
Vue.prototype.hwt =  Vue.observable({hwt:{}})
import TencentCloudChat from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
// import TIM from 'tim-js-sdk/tim-js-friendship.js';
import TIM from 'tim-js-sdk';
import mixin from './service/mixin';
Vue.mixin(mixin)



import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
let options = {
	SDKAppID: 1400819175 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// c27297d6641c68f9181f66ccb1bebff0f8666e64edd70eede58457279ff91195

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel  https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用


// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({
	'tim-upload-plugin': TIMUploadPlugin
});


// 注册腾讯云即时通信 IM 本地审核插件
tim.registerPlugin({
	'tim-profanity-filter-plugin': TIMProfanityFilterPlugin
});

Vue.prototype.TIM = TIM
Vue.prototype.tim = tim
import VueCompositionAPI from "@vue/composition-api";
// import { observable } from 'vue/types/umd';
Vue.use(VueCompositionAPI);

// import VueAwesomeSwiper from'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// Vue.use(VueAwesomeSwiper)
import '@/hhxx/css/css.css';
import '@/hhxx/css/reset.css';
new Vue({
    router,
    store,
    render: h => h(App),
    created(){
      // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
          setRemPc();
          window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
      },
  }).$mount('#app');
  
  //rem计算
  function setRemPc() {
    var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
    var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
    var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  }
  //window.addEventListener('load', setRemPc);




  // 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

// 富文本图片大小
import imageResize  from 'quill-image-resize-module' // 调整大小组件。
import { ImageDrop } from 'quill-image-drop-module'; // 拖动加载图片组件。
Quill.register('modules/imageResize', imageResize );
Quill.register('modules/imageDrop', ImageDrop);