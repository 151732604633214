import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    unreadnum:0
  },
  getters: {
  },
  mutations: {
    setunreadnum(state,num){
      console.log('state :>> ', state);
      state.unreadnum = num
    }
  },
  actions: {
  },
  modules: {
  }
})
