import axios from "axios";
import { BASE_URL } from "./config";
import { Message, Loading, MessageBox } from "element-ui";
import Router from "@/router";
import store from "@/store";
import qs from "qs";

let loading = null;

let config = {
  baseURL: BASE_URL,
  timeout: 3000, //设置最大请求时间
};
const showCustomMessageBox = (message, onConfirm, onCancel) => {
  const existingOverlay = document.getElementById('custom-message-overlay');
  if (existingOverlay) {
    document.body.removeChild(existingOverlay);
  }

  const overlay = document.createElement('div');
  overlay.id = 'custom-message-overlay';
  overlay.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  `;

  const messageBox = document.createElement('div');
  messageBox.style.cssText = `
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  `;

  const messageText = document.createElement('p');
  messageText.textContent = message;
  messageText.style.cssText = `
    margin-bottom: 20px;
    font-size: 16px;
    color: #333;
  `;

  const buttonContainer = document.createElement('div');
  buttonContainer.style.cssText = `
    display: flex;
    justify-content: space-around;
  `;

  const createButton = (text, isPrimary) => {
    const button = document.createElement('button');
    button.textContent = text;
    button.style.cssText = `
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      transition: background-color 0.3s;
      flex: 1;
      margin: 0 10px;
      border: none;
      ${isPrimary ? 
        `background-color: #593826; color: white;` : 
        `background-color: #f5f5f5; color: #333; border: 1px solid #dcdfe6;`
      }
    `;
    button.onmouseover = () => {
      button.style.backgroundColor = isPrimary ? '#6b4a30' : '#e6e6e6';
    };
    button.onmouseout = () => {
      button.style.backgroundColor = isPrimary ? '#593826' : '#f5f5f5';
    };
    return button;
  };

  const confirmButton = createButton('确定', true);
  const cancelButton = createButton('取消', false);

  confirmButton.onclick = () => {
    document.body.removeChild(overlay);
    if (onConfirm) onConfirm();
  };

  cancelButton.onclick = () => {
    document.body.removeChild(overlay);
    if (onCancel) onCancel();
  };
  buttonContainer.appendChild(confirmButton);
  buttonContainer.appendChild(cancelButton);
  messageBox.appendChild(messageText);
  messageBox.appendChild(buttonContainer);
  overlay.appendChild(messageBox);
  document.body.appendChild(overlay);
};


const _axios = axios.create(config);
const _axioa = axios.create(config);
/* 请求拦截器（请求之前的操作） */
/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
  (config) => {
    //如果有需要在这里开启请求时的loading动画效果
    loading = Loading.service({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    // config.headers.Authorization = getToken;
    return config;
  },
  (err) => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
  (res) => {
    //在这里关闭请求时的loading动画效果
    loading.close();
    if (res.data.code === 404) {
      MessageBox.confirm("token已过期，请重新登录后操作！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          store.dispatch("login/clearloginCache");
          // Router.push("/fernandobengua");
          Router.push("/");
        })
        .catch(() => {});
    }
    if (res.data.code === 400) {
      Message.error("请求网络失败");
    }
    if (res.data.code === 404) {
      Message.error("请求网络失败");
    }
    if (res.data.code == 0) {
      // Message.error(res.data.msg);
    }
    return res;
  },
  (err) => {
    if (err) {
      //在这里关闭请求时的loading动画效果
      loading.close();
      if (err.response.data.code === 401) {
        showCustomMessageBox(
          "请登录后操作！",
          () => {
            store.dispatch("login/clearloginCache");
            Router.push("/");
          },
          () => {
            // Handle cancel action if needed
          }
        );
      }  else if (err.response.data.code === 400) {
        Message.error("请求网络失败");
      } else if (err.response.data.msg === '请求令牌已过期') {
        Message.error("请重新登录后操作！");
       Router.push({ path: "/" });
      //  Router.push({ path: "/fernandobengua" });
      } else {
        Message.error(err.response.data.msg);
      }
    }
    return Promise.reject(err);
  }
);
var token = localStorage.getItem("token")
// var access_token = JSON.parse(localStorage.getItem("access_token"));
//封装post,get方法
const request = {
  get(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          // Authorization: `${token_type} ${access_token}`,
        },
        method: "GET",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  posta(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          token: `${token}`,
        },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postl(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axioa({
        url,
        data: params,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          token: `${token}`,
        },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postc(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  geta(url = "", params = {}) {
    return new Promise((resolve, reject) => {
      _axios({
        url,
        data: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: `${token}`,
        },
        method: "GET",
      })
        .then((res) => {
          resolve(res.data);
          return res;
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default request;
