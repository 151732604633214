<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

import { userindex } from "@/service/api";
import { genTestUserSig } from '@/service/GenerateTestUserSig';

export default {
  mounted() {
    var that = this
    var token = localStorage.getItem("token")
    console.log('token1', token);

    const excludedPaths = ['/school'];  // exclude from redirection
    const currentPath = this.$route.path;

    if (token) {
      console.log('token2', token);
      setTimeout(() => {
        this.userindex()
      }, 0);
    } else {
      console.log('token3', token);
      // that.$message.error("请重新登录后操作！");
      // that.$router.push('/fernandobengua')
      // if (!excludedPaths.includes(currentPath)) {
      //   that.$router.push('/');
      // }
    }
  }
  , methods: {
    async userindex() {
      const companyResult = await userindex();
      this.listnat = companyResult.data
      this.hwt.hwt = companyResult.data

      this.hwt.hwt = companyResult.data
      console.log('this.hwt.hwt :>> ', this.hwt.hwt);
      this.logins(this.hwt.hwt.id)
    },
    async logins(uname) {
      let that = this
      console.log('hah', uname);
      // let userSig = genTestUserSig(uname.toString()).userSig
      // return
      let userSig = genTestUserSig(uname + '')
      console.log(userSig, '99999999')
      // return ;
      let promise = this.tim.login({
        userID: uname.toString(),
        userSig: userSig.userSig
      });
      promise.then(function (imResponse) {
        console.log(imResponse.data, `登录成功${uname}`); // 登录成功
        console.log(imResponse.data.errorInfo);
        // console.log(that.$store, 1232, uname);
        if (imResponse.data.repeatLogin === true) {
          // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
        }

        setTimeout(() => {
          let promises = that.tim.getConversationList();
          promises.then(function (imResponse) {
            const conversationList = imResponse.data.conversationList; // 全量的会话列表，用该列表覆盖原有的会话列表
            const isSyncCompleted = imResponse.data.isSyncCompleted; // 从云端同步会话列表是否完成
            console.log(imResponse.data.conversationList, '会话列表');
            // unreadCount
            let y = imResponse.data.conversationList
            let k = y.reduce((pre, cur, index, arr) => pre + cur.unreadCount, 0)  //未读消息数量

            that.$store.commit('setunreadnum', k)

          }).catch(function (imError) {
            console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
          });
          // JSON.parse(that.$store.state.userInfo)
          console.log('this.hwt.hwt :>> ', that.hwt.hwt);
          let promise = that.tim.updateMyProfile({
            nick: that.hwt.hwt.nickname,
            avatar: that.hwt.hwt.avatar,
            gender: that.TIM.TYPES.GENDER_MALE,

            allowType: that.TIM.TYPES.ALLOW_TYPE_ALLOW_ANY
          });
          promise.then(function (imResponse) {
            console.log(imResponse.data, '更新资料成功'); // 更新资料成功
            // console.log('that.tim.getLoginUser() :>> ', that.tim.GetLoginUserID());
          }).catch(function (imError) {
            console.warn('updateMyProfile error:', imError); // 更新资料失败的相关信息
          });

        }, 2000)

      }).catch(function (imError) {
        console.warn('login error:', imError); // 登录失败的相关信息
      });
    },

  }


}
// vue 2.7 及以上
// import { TUICallKit } from "@tencentcloud/call-uikit-vue2";
// vue 2.6 及 以下, 请注释上行，开放下行注释
// import { TUICallKit } from "@tencentcloud/call-uikit-vue2.6";
</script>
<style lang="scss">
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* CSS Document */
/* html{font-size: 100px !important;} */
html,
body,
div,
span,
iframe,
input,
select,
map,
font,
img,
a img,
samp,
strong,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
b,
p,
blockquote,
a,
address,
code,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
tr,
td,
th {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  font-family: -apple-system, SF UI Text, Arial, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, WenQuanYi Micro Hei, sans-serif, SimHei, SimSun;
}

li,
ul {
  list-style: none;
}

button {
  vertical-align: middle;
  cursor: pointer;
}

input,
button,
textarea {
  vertical-align: middle;
  border: none;
  font-family: "微软雅黑";
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  resize: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}

a {
  text-decoration: none;
  /* transition: all .25s; */
}

i,
em {
  font-style: normal;
}

a:focus {
  outline: none;
}

a.hidefocus {
  outline: none;
}

input[type="text"],
input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  /*去除iPhone input默认样式}*/
}

input {
  outline: none;
  background: none;
}

img {
  display: block;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  background-color: #fff !important;
}


.el-pagination.is-background .el-pager li{
  width: .4rem ;
  height: .4rem ;
  background-color: #ffffff;
  line-height: .4rem ;
  font-size: .2rem ;
}

 .el-pagination button{
  width: .4rem !important;
  height: .4rem !important;
  background-color: #ffffff !important;
  line-height: .4rem !important;
  font-size: .2rem !important;
}
.middlelos .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #945C48;
}
</style>
