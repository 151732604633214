import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数

const routes = [
  {
    path: "/",
    name: "home",
    component: (resolve) => require(["@/views/index.vue"], resolve),
  },
  {
    path: "/source",
    name: "source",
    component: (resolve) => require(["@/views/source.vue"], resolve),
  },
  {
    path: "/briefs",
    name: "briefs",
    component: (resolve) => require(["@/views/briefs.vue"], resolve),
  },
  {
    path: "/categories",
    name: "categories",
    component: (resolve) => require(["@/views/categories.vue"], resolve),
  },
  {
    path: "/fernandobengua",
    name: "fernandobengua",
    component: (resolve) => require(["@/views/fernandobengua.vue"], resolve),
  },
  {
    path: "/information",
    name: "information",
    component: (resolve) => require(["@/views/information.vue"], resolve),
  },
  {
    path: "/Orders",
    name: "Orders",
    component: (resolve) => require(["@/views/Orders.vue"], resolve),
  },
  {
    path: "/payments",
    name: "payments",
    component: (resolve) => require(["@/views/payments.vue"], resolve),
  },
  {
    path: "/professional",
    name: "professional",
    component: (resolve) => require(["@/views/professional.vue"], resolve),
  },
  {
    path: "/Session",
    name: "Session",
    component: (resolve) => require(["@/views/Session.vue"], resolve),
  }, 
  {
    path: "/select",
    name: "select",
    component: (resolve) => require(["@/views/select.vue"], resolve),
  },
  {
    path: "/school",
    name: "school",
    component: (resolve) => require(["@/views/school.vue"], resolve),
  },
  {
    path: "/seller_dashboard",
    name: "seller_dashboard",
    component: (resolve) => require(["@/views/seller_dashboard.vue"], resolve),
  },
  {
    path: "/chat",
    name: "chat",
    component: (resolve) => require(["@/views/sendbird.vue"], resolve),
  },
  {
    path: "/deposit",
    name: "deposit",
    component: (resolve) => require(["@/views/deposit.vue"], resolve),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: (resolve) => require(["@/views/withdraw.vue"], resolve),
  },
  {
    path: "/Collection",
    name: "Collection",
    component: (resolve) => require(["@/views/Collection.vue"], resolve),
  },
  {
    path: "/release",
    name: "release",
    component: (resolve) => require(["@/views/release.vue"], resolve),
  },
  {
    path: "/Login",
    name: "Login",
    component: (resolve) => require(["@/views/mobileLogin.vue"], resolve),
  },
  {
    path: "/evaluate",
    name: "evaluate",
    component: (resolve) => require(["@/views/evaluate.vue"], resolve),
  },
  {
    path: "/feedback",
    name: "feedback",
    component: (resolve) => require(["@/views/feedback.vue"], resolve),
  },
  {
    path: "/forumindex",
    name: "forumindex",
    component: (resolve) => require(["@/views/forumindex.vue"], resolve),
  },
  {
    path: "/refund",
    name: "refund",
    component: (resolve) => require(["@/views/refund.vue"], resolve),
  },
  {
    path: "/complaint",
    name: "complaint",
    component: (resolve) => require(["@/views/complaint.vue"], resolve),
  },
  {
    path: "/taskcomplaint",
    name: "taskcomplaint",
    component: (resolve) => require(["@/views/taskcomplaint.vue"], resolve),
  },
  {
    path: "/articleList",
    name: "articleList",
    component: (resolve) => require(["@/views/articleList.vue"], resolve),
  },
  {
    path: "/articleListlist",
    name: "articleListlist",
    component: (resolve) => require(["@/views/articleListlist.vue"], resolve),
  },
  {
    path: "/forumilist",
    name: "forumilist",
    component: (resolve) => require(["@/views/forumilist.vue"], resolve),
  },

  {
    path: "/forumilist1",
    name: "forumilist1",
    component: (resolve) => require(["@/views/forumilist1.vue"], resolve),
  },
  {
    path: "/forumilist2",
    name: "forumilist2",
    component: (resolve) => require(["@/views/forumilist2.vue"], resolve),
  },

  {
    path: "/TaskCenter",
    name: "TaskCenter",
    component: (resolve) => require(["@/views/TaskCenter.vue"], resolve),
  },

  {
    path: "/Publishtasks",
    name: "Publishtasks",
    component: (resolve) => require(["@/views/Publishtasks.vue"], resolve),
  },

  {
    path: "/Publishtasks1",
    name: "Publishtasks1",
    component: (resolve) => require(["@/views/Publishtasks1.vue"], resolve),
  },
  {
    path: "/Publishtasks2",
    name: "Publishtasks2",
    component: (resolve) => require(["@/views/Publishtasks2.vue"], resolve),
  },
  {
    path: "/Publishtasks3",
    name: "Publishtasks3",
    component: (resolve) => require(["@/views/Publishtasks3.vue"], resolve),
  },
  {
    path: "/lotteryor",
    name: "lotteryor",
    component: (resolve) => require(["@/views/lotteryor.vue"], resolve),
  },

  {
    path: "/modify",
    name: "modify",
    component: (resolve) => require(["@/views/modify.vue"], resolve),
  },

  {
    path: "/categories1",
    name: "categories1",
    component: (resolve) => require(["@/views/categories1.vue"], resolve),
  },
  {
    path: "/authentication",
    name: "authentication",
    component: (resolve) => require(["@/views/authentication.vue"], resolve),
  },
  {
    path: "/myCoupon",
    name: "myCoupon",
    component: (resolve) => require(["@/views/coupon.vue"], resolve),
  },
  {
    path: "/schoolSearchMob",
    name: "schoolSearchMob",
    component: (resolve) => require(["@/views/schoolSearchMob.vue"], resolve),
  }
  
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// const router = new VueRouter({
//   mode: "hash", history
//   base: '/salaryqa/',
//   routes,
// });

export default router;
