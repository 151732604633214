import Vue from 'vue'
export default {
  data() {
    return {
    //   $baseImgUrl: Vue.$baseImgUrl,
	  // stores:Vue.prototype.$store.state 
    }
  },
  created(){
    let that = this
    if(this.hwt.hwt.id){
      let promise = this.tim.getConversationList();
      promise.then(function(imResponse) {
        const conversationList = imResponse.data.conversationList; // 全量的会话列表，用该列表覆盖原有的会话列表
        const isSyncCompleted = imResponse.data.isSyncCompleted; // 从云端同步会话列表是否完成
        console.log(imResponse.data.conversationList, '会话列表');
        // unreadCount
        let y = imResponse.data.conversationList
        let k = y.reduce((pre,cur,index,arr)=>pre+cur.unreadCount,0)  //未读消息数量
        console.log('k ,未读数:>> ', k);
        that.$store.commit('setunreadnum',k)
      }).catch(function(imError) {
        console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
      });
    }

  }
}
